import { SET_CERTIFICATE } from './-mutation-types';
import { useCertificatesStore } from '~/stores/certificates';
import CertificateService from '~/services/api/crm/CertificateService';
import { type Certificate } from '~/models/Certificate';

const Actions = {
    async fetchCertificate(certificateId: number) {
        const certificatesStore = useCertificatesStore();
        const chamberService = new CertificateService();
        const response = await chamberService.fetchCertificate(certificateId);

        certificatesStore[SET_CERTIFICATE](response);
    },
    setCertificate(courseCertificate: Certificate | null) {
        const certificatesStore = useCertificatesStore();
        certificatesStore[SET_CERTIFICATE](courseCertificate);
    },
};

export default Actions;
