import { type Certificate } from '~/models/Certificate';

export type CertificateState = {
    certificate: Certificate | null;
}

export const defaultStateData = (): CertificateState => ({
    certificate: null,
});

export const DefaultState = (): CertificateState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
